
import { Switch, Text } from '@fluentui/react-components';
import { useStyles } from '../../../themes.ts';


function HeaderTop({ toggleTheme}) {
    const classes = useStyles();
    return (
        <div className={classes.HeaderTop}>
            <Text > &copy; RHEM Labs Pty Ltd ACN: 672 769 354 </Text>
            <Switch onClick={toggleTheme} aria-label="Toggle Dark Mode"/>
        </div>
    );
}

export default HeaderTop;