// Header.js
import React, { useState, useEffect } from 'react';

import HeaderTop from './header_top';
import HeaderMain from './header_main';
import HeaderBottom from './header_bottom';
import HeaderMobile from './HeaderMobile';

function Header(props) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    return (
        <header>
            {isMobile ? (
                <HeaderMobile toggleTheme={props.toggleTheme}/>
            ) : (
                <>
                    <HeaderTop toggleTheme={props.toggleTheme} />
                    <HeaderMain />
                    <HeaderBottom />
                </>
            )}
        </header>
    );
}

export default Header;