import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardPreview,
  CardFooter,
  Text,
  Display,
  Subtitle1,
  Divider,
  
} from "@fluentui/react-components";

import { Link } from 'react-router-dom';

import { PersonSearchRegular } from '@fluentui/react-icons';

import ForensicBannerImage from '../../assets/3-1-BannerImage-Forensic-1.png';
import AIBannerImage from'../../assets/3-1-BannerImage-AI-1.png';
import LawBannerImage from '../../assets/3-1-BannerImage-Law-1.png';
import HeroImage from '../../assets/generated_00 (1).png';

import { useStyles } from '../../themes.ts'


export default function Home() {
  const classes = useStyles();

  return (
  <>
    <Divider 
    className={classes.CustomDivider}
  >

  </Divider>
  <Divider 
    className={classes.CustomVerticalDivider}
    vertical
  >
    
  </Divider>
<div className={classes.Hero}>
  <div className={classes.HeroImageContainer}>
    <img 
      src={HeroImage} 
      alt="Decorative, showcasing a Forensic Expert at work in the Lab" 
      className={classes.HeroImage}
    />
  </div>
  <div className={classes.HeroTextContainer}>
    <Display>Innovating With AI</Display>
  </div>
</div>
 
<Divider 
  className={classes.CustomVerticalDivider}
  vertical
>

</Divider>
<Divider 
  className={classes.CustomDivider}
>

</Divider>

    <div className={classes.cardGrid}>

      <Card className={classes.SectionCard}>
        <CardPreview className={classes.SectionPreviewImage}>
          <img 
            src={ForensicBannerImage} 
            alt="Decorative, showcasing a Forensic Expert at work in the Lab" 
          /> 
        </CardPreview>

        <CardHeader className={classes.SectionHeader}
          header={<Display>Forensic Experts</Display>}
          description={<Subtitle1 style={{height: `${1.5 * 3}em`}}>Helping you understand digital evidence with precision and confidentiality.</Subtitle1>}
        />

        <CardFooter className={classes.SectionFooter}>
          <Link to="/rapid-review" className={classes.linkButton}>
            <Button className={classes.Button} icon={< PersonSearchRegular /> } appearance="primary" as="span">Book a Free Assessment</Button>
          </Link>
          <Link to="/contact" className={classes.linkButton}>
            <Button appearance="outline" as="span">Contact</Button>
          </Link>
        </CardFooter>

        <Text className={classes.SectionBody}>
          <p>We provide unmatched expertise in Digital Forensics, offering top-tier services tailored to your unique needs.Servicing jurisdictions around the world, we offer expert witness testimony and in-depth forensic analysis from our headquarters in Adelaide, South Australia delivering confidentiality and excellence in every case.</p>
        </Text>

      </Card>

      <Card className={classes.SectionCard}>

      <CardPreview className={classes.SectionPreviewImage}>
          <img 
            src={AIBannerImage} 
            alt="An advanced Generative AI Agent capable of generating diagrams, charts, code and writing copy. The AI Agent generating Gantt charts to help the user." 
          /> 
        </CardPreview>

        <CardHeader className={classes.SectionHeader}
          header={<Display>Era Of <br/>AI</Display>}
          description={<Subtitle1 style={{height: `${1.5 * 3}em`}}> Access LoganAI, your expert general purpose AI assistant.</Subtitle1>}
        /> 

        <CardFooter className={classes.SectionFooter}>
          <Button className={classes.Button} appearance="primary">Start Your 14 Day Free Trial</Button>
          <Button appearance="outline">Learn More</Button>  
        </CardFooter>

        <Text className={classes.SectionBody}>
          <p>LoganAI, your premier solution for Generative AI that generates diagrams, charts, code, and compelling copy tailored to your needs. Our innovative AI technology assures confidentiality and excellence, empowering you with advanced tools for any project.</p>
        </Text>

      </Card>

      <Card className={classes.SectionCard}>

      <CardPreview className={classes.SectionPreviewImage}>
          <img 
            src={LawBannerImage} 
            alt="An advanced Generative AI Agent capable of generating diagrams, charts, code and writing copy. The AI Agent generating Gantt charts to help the user." 
            /> 
        </CardPreview>

        <CardHeader className={classes.SectionHeader}
            header={<Display>AI For <br/>Law</Display>}
            description={<Subtitle1 style={{height: `${1.5 * 3}em`}}>
                Secure, Private AI for Legal Professionals and Experts
              </Subtitle1>
            }
        />

        <CardFooter className={classes.SectionFooter}>
          <Button appearance="outline" className={classes.Button}>Join The Waitlist</Button>
        </CardFooter> 

        <Text className={classes.SectionBody}>
        <p>The definitive AI solution tailored for lawyers and expert witnesses. Empower your practice with cutting-edge AI technology designed for the legal field.</p>
        </Text>

      </Card>

    </div>

  <Divider 
    className={classes.CustomDivider}
  >

  </Divider>
  <Divider 
    className={classes.CustomVerticalDivider}
    vertical
  >
    
  </Divider>
  </>
  );
}