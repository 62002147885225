// headerMain.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Button } from '@fluentui/react-components';
import RHEMLogo from '../../../assets/logo.jpeg';
import { useStyles } from '../../../themes.ts';


function HeaderMain() {
    const classes = useStyles();
    return (
        <div className={classes.HeaderMain}>
            <div className={classes.HeaderColumnLeft}>
            <Image
                        src={RHEMLogo}
                        alt="RHEM Labs Logo"
                        height={48}
                        width={48}
                    />
                <Link to="/" className={classes.HeaderButton}> 
                    <span className={classes.HeaderText}>RHEM Labs</span>
                </Link>
            </div>
            <div className={classes.HeaderColumnRight}>
                <Button 
                    appearance="outline" 
                    as="a" 
                    href="https://funnels.rhemlabs.com.au/resources/loganai" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    Client Login
                </Button>
                <Button 
                    className={classes.Button}
                    as="a" 
                    href="https://funnels.rhemlabs.com.au/demodeal" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    Try LoganAI
                </Button>
            </div>
        </div>
    );
}

export default HeaderMain;