// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import { FluentProvider } from '@fluentui/react-components';
import { customLightTheme, customDarkTheme, useStyles } from './themes.ts';

// Components
import Header from './components/shared/header/Header'; 
import Home from './pages/home/Home';
import CategoryPage from './components/blog/CategoryPage';
import PostPage from './components/blog/PostPage';
import Footer from './components/shared/footer/Footer'; 
import BookingPage from './pages/Bookings.js';
import AboutPage from './pages/about/About.js';
import ContactPage from './pages/contact/Contact.js';

const App = () => {
  const [theme, setTheme] = useState(customLightTheme);
  const styles = useStyles(theme); 

  const toggleTheme = () => {
    setTheme(currentTheme => 
      currentTheme === customLightTheme ? customDarkTheme : customLightTheme
    );
  };

  return (
    <FluentProvider theme={theme}> 
      <Router>
        <Header toggleTheme={toggleTheme}/>
        <div className={styles.container}>
          <main>
            <Routes> 
              <Route path="/" element={<Home />} />
              <Route path="/category/:categoryName" element={<CategoryPage />} />
              <Route path="/category/:categoryName/post/:postName" element={<PostPage />} />
              <Route path="/rapid-review" element={<BookingPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>
        </div>
        <Footer />
      </Router>
    </FluentProvider>
  );
};

export default App;