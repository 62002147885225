import React from 'react';

const FooterBase = () => {
  return (
    <div className="footer-base">
      {/* Add your content for the base section of the footer here */}
      <p>Built using React, Fluent 2 and the Support of LoganAI's ElemenTools</p>
    </div>
  );
};

export default FooterBase;