import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image, MenuButton, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Switch } from '@fluentui/react-components';
import { Home32Regular, LineHorizontal320Regular,  DarkThemeRegular } from '@fluentui/react-icons';
import RHEMLogo from '../../../assets/logo.jpeg';
import { useStyles } from '../../../themes.ts';


function HeaderMobile( { toggleTheme }) {
    const navigate = useNavigate();
    const [topCategories, setTopCategories] = useState([]);
    const styles = useStyles();

    useEffect(() => {
        const fetchTopCategories = async () => {
            try {
                const categoriesResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/categories');
                const categoriesData = await categoriesResponse.json();

                const postsResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/posts');
                const postsData = await postsResponse.json();

                const categoryPostCounts = new Map();
                postsData.forEach(post => {
                    post.categories.forEach(categoryId => {
                        categoryPostCounts.set(categoryId, (categoryPostCounts.get(categoryId) || 0) + 1);
                    });
                });

                const categoriesWithCounts = categoriesData
                    .map(category => ({ 
                        ...category, 
                        count: categoryPostCounts.get(category.id) || 0 
                    }))
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 4);

                const subcategoriesPromises = categoriesWithCounts.map(async category => {
                    const subcategoriesResponse = await fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/categories?parent=${category.id}`);
                    const subcategoriesData = await subcategoriesResponse.json();
                    const subcategoriesWithCounts = subcategoriesData.map(subcategory => ({
                        ...subcategory,
                        count: categoryPostCounts.get(subcategory.id) || 0
                    }));
                    return { ...category, subcategories: subcategoriesWithCounts };
                });

                const categoriesWithSubcategories = await Promise.all(subcategoriesPromises);

                setTopCategories(categoriesWithSubcategories);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchTopCategories();
    }, []);

    const handleMenuClick = (event, slug) => {
        event.preventDefault();
        navigate(`/category/${slug}`);
    };

    const handleLinkClick = (event, slug) => {
        event.preventDefault();
        navigate(`${slug}`);
    };

    return (
        <div className={styles.HeaderMain}>
            
            <div className={styles.HeaderColumnLeft}>
                <Image
                            src={RHEMLogo}
                            alt="RHEM Labs Logo"
                            height={48}
                            width={48}
                        />
                    <Link to="/" className={styles.HeaderButton}> 
                        <span className={styles.HeaderText}>RHEM Labs</span>
                    </Link>
            </div>

            <div className={styles.container}>
                <Menu>
                    <MenuTrigger>
                        <MenuButton 
                            icon={<LineHorizontal320Regular />} 
                            className={styles.menuButton}
                            appearance="subtle"
                        />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={(event) => handleLinkClick(event, '/')} icon={<Home32Regular />} className={styles.centeredIcon}>
                            </MenuItem>
                            <MenuItem className={styles.centeredIcon}>
                                <Switch onClick={toggleTheme} label="Dark Mode" icon={<DarkThemeRegular />} className={styles.centeredIcon}/>
                                
                            </MenuItem>    
                            
                            {topCategories.map(category => (
                                <Menu key={category.id}>
                                    <MenuTrigger>
                                        <MenuItem
                                            onClick={(event) => ""}
                                            disabled={category.count === 0}
                                        >
                                            {category.name}
                                        </MenuItem>
                                    </MenuTrigger>
                                    {category.subcategories.length > 0 && (
                                        <MenuPopover>
                                            <MenuList>
                                            <MenuItem
                                            onClick={(event) => handleMenuClick(event, category.slug)}
                                            className={styles.centeredIcon}
                                        >
                                            {category.name}
                                        </MenuItem>
                                                {category.subcategories.map(subcategory => (
                                                    
                                                    <MenuItem
                                                        key={subcategory.id}
                                                        onClick={(event) => handleMenuClick(event, subcategory.slug)}
                                                        className={styles.nestedItem}
                                                        disabled={subcategory.count === 0}
                                                    >
                                                        {subcategory.name}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </MenuPopover>
                                    )}
                                </Menu>
                            ))}
                            <MenuItem onClick={(event) => handleLinkClick(event, 'who-we-are')} >
                                Who we are
                            </MenuItem>
                            <MenuItem onClick={(event) => handleLinkClick(event, 'our-results')} >
                                Our Results
                            </MenuItem>

                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        </div>
    );
}

export default HeaderMobile;