import React from 'react';
import { 
    LargeTitle, 
    Title1, 
    Title2, 
    Title3, 
    Subtitle1, 
    Subtitle2, 
    Body1 
} from '@fluentui/react-components';
import ImageWithCaption from './ImageWithCaption';
import './article_renderer.css';

const ArticleRenderer = ({ elements = [] }) => {
  const capitalizeWords = (text) => {
    return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const renderElement = (el, index) => {
    switch (el.tagName.toLowerCase()) {
      case 'h1':
        return <div key={index}><LargeTitle>{capitalizeWords(el.textContent)}</LargeTitle></div>;
      case 'h2':
        return <div key={index}><Title1>{capitalizeWords(el.textContent)}</Title1></div>;
      case 'h3':
        return <div key={index}><Title2>{capitalizeWords(el.textContent)}</Title2></div>;
      case 'h4':
        return <div key={index}><Title3>{capitalizeWords(el.textContent)}</Title3></div>;
      case 'h5':
        return <div key={index}><Subtitle1>{capitalizeWords(el.textContent)}</Subtitle1></div>;
      case 'h6':
        return <div key={index}><Subtitle2>{capitalizeWords(el.textContent)}</Subtitle2></div>;
      case 'p':
        return <div key={index}><Body1>{el.textContent}</Body1></div>;
      case 'figure':
        const img = el.querySelector('img');
        const caption = el.querySelector('figcaption');
        return (
          <figure key={index}>
            <ImageWithCaption
              src={img.src}
              alt={img.alt}
              caption={caption ? caption.innerHTML : null}
            />
          </figure>
        );
      case 'ul':
        return (
          <ul key={index}>
            {Array.from(el.children).map((li, liIndex) => (
              <li key={liIndex}><Body1>{li.textContent}</Body1></li>
            ))}
          </ul>
        );
      case 'ol':
        return (
          <ol key={index}>
            {Array.from(el.children).map((li, liIndex) => (
              <li key={liIndex}><Body1>{li.textContent}</Body1></li>
            ))}
          </ol>
        );
      case 'blockquote':
        return <blockquote key={index}><Body1 italic>{el.textContent}</Body1></blockquote>;
      default:
        return <div key={index}><Body1>{el.textContent}</Body1></div>;
    }
  };

  return (
    <div className="article-renderer">
      {elements.map((el, index) => renderElement(el, index))}
    </div>
  );
};

export default ArticleRenderer;