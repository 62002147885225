import { createDarkTheme, createLightTheme, BrandVariants, makeStyles, tokens, } from '@fluentui/react-components';

export const rhemlabs: BrandVariants = { 
  10: "#020401",
  20: "#111C0D",
  30: "#162F13",
  40: "#1A3D16",
  50: "#1C4B19",
  60: "#1F5A1C",
  70: "#20691E",
  80: "#227820",
  90: "#228822",
  100: "#2B982A",
  110: "#4DA445",
  120: "#69B15F",
  130: "#82BE78",
  140: "#9BCB91",
  150: "#B3D7AB",
  160: "#CBE4C5"
};

export const neutralColors = {
  10: "#030303",
  20: "#171717",
  30: "#252525",
  40: "#313131",
  50: "#3d3d3d",
  60: "#494949",
  70: "#565656",
  80: "#636363",
  90: "#717171",
  100: "#7f7f7f",
  110: "#8d8d8d",
  120: "#9b9b9b",
  130: "#AAAAAA",
  140: "#B9B9B9",
  150: "#C8C8C8",
  160: "#D7D7D7"
};



export const useStyles = makeStyles({
  HeaderTop: {
    flex: 'auto',
    backgroundColor: tokens.colorNeutralBackground6,
    color: tokens.colorNeutralForeground4,
    padding: '1px 20px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  },

  HeaderMain: {
    backgroundColor: tokens.colorNeutralBackground3,
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  HeaderColumnLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  
  HeaderButton: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
  },
  
  HeaderText: {
    marginLeft: '10px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  
  HeaderColumnRight: {
    display: 'flex',
    gap: '10px',
  },

  HeaderBottom: {
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: tokens.colorNeutralBackground6,
    color: tokens.colorNeutralForeground3,
    height: '3em',
  },
  
  categoryMenuButton: {
      textDecoration: 'none', 
      fontSize: '1.05vw',
    },
  nestedItem: {
      marginLeft: '20px', 
  },
  bulletIcon: {
      marginRight: '5px', 
  },

  Hero: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: "'image' 'text'",
    '@media (min-width: 768px)': {
      gridTemplateAreas: "'image text'",
    },
  },
  
  HeroImageContainer: {
    gridArea: 'image',
  },
  
  HeroImage: {
    width: '100%',
    height: 'auto',
  },
  
  HeroTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    gridArea: 'text',
    margin: '20px',
  },

  /*Basic Layout Containers*/
  container1col: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'space-evenly',
    '& > *': {  
      flex: '1 1 calc(100% - 16px)',
    }
  },
  container2col: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'space-evenly',
    '& > *': {  
      flex: '1 1 calc(50% - 16px)',
    }
  },
  container3col: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'space-evenly',
    '& > *': {  // Apply to all child elements
      flex: '1 1 calc(33.33% - 16px)',
    }
  },
  container4col: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'space-evenly',
    '& > *': {  // Apply to all child elements
      flex: '1 1 calc(25% - 16px)',
    }
  },

  mainContent: {
    flex: 1,
    padding: '16px',
  },

  footer: {
    backgroundColor: rhemlabs[100],
    color: 'white',
    padding: '8px',
  },

  link: {
    color: rhemlabs[100],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  // Default cards
  card: {
    flex: "1 1 400px",
    maxWidth: "400px",
    height: "fit-content",
  },

  cardHeader: {
    minHeight: "250px", 
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },

  cardBody: {
    padding: "16px",
    minHeight: "20px", 
  },

  cardPreviewImage: {
    width: "100%", 
    height: "200px", 
    objectFit: "cover", 
    objectPosition: "50% 21%" 
  },

  cardFooter: {
    padding: "16px",
    minHeight: "20px", 
  },

  // Hero card
  HeroCard: {
    width: "100%",
    maxWidth: "100%",
    height: "fit-content",
  },

  HeroHeader: {
    width: "100%", 
    backgroundColor: "var(--white)", 
    color: "var(--colorNeutralForeground1)", 
    fontSize: "600",
    margin: "-10", 
  },

  HeroPreviewImage: {
    width: "100%", 
    height: "200px", 
    objectFit: "cover", 
    objectPosition: "50% 21%" 
  },

  HeroBody: {
    width: "100%", 
    backgroundColor: tokens.colorNeutralBackground1, 
    color: tokens.colorNeutralForeground1, 
    fontSize: "400"
  },

  HeroFooter: {
    padding: "16px",
    minHeight: "20px"
  },

  // Section styles
  SectionCard: {
    width: "100%",
    height:"auto",
    padding: "5px 0",
    backgroundColor: tokens.colorNeutralBackground4,
  },

  SectionPreviewImage: {
    width: "auto",
    height: "200px",
    objectFit: "cover"
  },

  SectionHeader: {
    paddingLeft: "16px",
  },

  SectionBody: {
    paddingLeft: "32px",
    paddingRight: "16px",
  },

  SectionFooter: {
    padding: "16px",
    minHeight: "20px", 
  },

  // Testimonial card styles
  TestimonialCard: {
    flex: "1 1 300px",
    maxWidth: "300px",
    height: "fit-content",
    margin: "10px",
    padding: "20px",
    backgroundColor: "var(--colorNeutralBackground2)", 
    color: "var(--colorNeutralForeground2)", 
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  TestimonialHeader: {
    fontSize: "600", // Example font size
    marginBottom: "10px",
  },

  TestimonialPreviewImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    objectPosition: "50% 21%",
  },

  TestimonialBody: {
    fontSize: "400", // Example font size
    color: "var(--colorNeutralForeground2)", // Assuming this is a valid neutral foreground token
  },

  TestimonialFooter: {
    padding: "16px",
    minHeight: "20px", // Ensuring a minimum height for alignment
  },

  CustomDivider: {
    height: "20px",
  },

  CustomVerticalDivider: {
    height: "50px",
  },

  Button: {
    background: `linear-gradient(45deg, ${rhemlabs[110]} 0%, ${rhemlabs[90]} 100%)`, // Subtle gradient from top left to bottom right using RHEM Labs tokens
    color: tokens.colorNeutralBackground1,  
    padding: '10px 20px',
    border: `1px solid ${rhemlabs[100]}`,  
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background 0.3s ease, color 0.3s ease',  // Smooth transition for Fluent2 compliance
    '&:hover': {
      background: `linear-gradient(45deg, ${rhemlabs[140]} 0%, ${rhemlabs[110]} 100%)`, // Reversed gradient on hover
      color: tokens.colorNeutralForeground1Hover,
    },

    '&:focus': {
      outline: `2px solid ${rhemlabs[50]}`,  // Focus outline for accessibility
      outlineOffset: '2px',
    },
  },

   /*gird layout for cards*/
   cardGrid: { 
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(250px, 1fr))', 
    gap: '16px',
    padding: '16px', 
    margin: '16px',

    // Media Query for Mobile Portrait 
    '@media (max-width: 768px)': { 
        gridTemplateColumns: '1fr', 
    },

      // Media Query for medium devices (768px to 1024px)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    gridTemplateColumns: 'repeat(2, minmax(250px, 1fr))', 
},

    // Media Query for Mobile Landscape 
    '@media (orientation: landscape) and (max-width: 768px)': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', 
    },
  },

  ContactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',

    margin: '0 auto',
  },
  FormGroup: {
    marginBottom: '1rem',
    width: '400px',

  },
  
});

export const customLightTheme = createLightTheme(rhemlabs);
export const customDarkTheme = createDarkTheme(rhemlabs);
