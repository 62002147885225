import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Display, 
    Image 
} from '@fluentui/react-components';
import ArticleRenderer from './ArticleRenderer';
import './post_page.css';

function PostPage() {
    // eslint-disable-next-line no-unused-vars 
    const { categoryName, postName } = useParams(); 
    const [post, setPost] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);

    useEffect(() => {
        // Fetch post by slug
        fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/posts?slug=${postName}`)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    setPost(data[0]);
                    console.log('Post data:', data[0]);
    
                    // Fetch featured media
                    if (data[0].featured_media) {
                        fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/media/${data[0].featured_media}`)
                            .then(response => response.json())
                            .then(mediaData => {
                                console.log('Media data:', mediaData);
                                // Check if source_url is available
                                if (mediaData.source_url) {
                                    const baseURL = 'https://rl.azurewebsites.net';
                                    setFeaturedImage(baseURL + mediaData.source_url);
                                } else {
                                    console.error('No source_url available');
                                }
                            })
                            .catch(error => console.error('Error fetching media:', error));
                    } else {
                        console.log('No featured media');
                    }
                } else {
                    console.log('No posts found');
                }
            })
            .catch(error => console.error('Error fetching post:', error));
    }, [postName]);

    // Function to parse post content into blocks
    const parseContent = (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        return Array.from(doc.body.children);
    };

    // Function to capitalize the first letter of each word
    const capitalizeTitle = (title) => {
        return title.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    const getImageSize = () => {
        const width = window.innerWidth;
        if (width >= 1200) {
            return { width: 1600, height: 600 };
        } else if (width >= 768) {
            return { width: 1200, height: 450 };
        } else {
            return { width: 800, height: 300 };
        }
    };
    // eslint-disable-next-line no-unused-vars 
    const [imageSize, setImageSize] = useState(getImageSize());

    useEffect(() => {
        const handleResize = () => {
            setImageSize(getImageSize());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!post) {
        return <div>Loading...</div>;
    }

    const elements = parseContent(post.content.rendered);

    return (
    <>
        <div className="feature-image-container">
            {featuredImage && (
                <Image 
                    src={featuredImage}
                    alt={post.title.rendered} 
                    fit="cover"
                    width="100%"
                    height="400px"
                />
            )}
        </div>
        <div className="post-page">
            <div className="title-container">
                {post.title && 
                <Display align="center">{capitalizeTitle(post.title.rendered)}</Display>}
            </div>
            <div className="post-content">
                <ArticleRenderer elements={elements} />
            </div>
        </div>
    </>
);
}

export default PostPage;