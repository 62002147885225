// components/shared/footer/Footer.js
import React from 'react';
import { Text } from '@fluentui/react-components';
import FooterTop from './FooterTop';
import FooterMain from './FooterMain';
import FooterBase from './FooterBase';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f3f3f3', padding: '20px 0' }}>
      <FooterTop />
      <FooterMain />
      <FooterBase />
      <Text align="center" style={{ marginTop: '20px' }}>© 2024 RHEM Labs Pty Ltd</Text>
    </footer>
  );
};

export default Footer;