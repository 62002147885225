import React from 'react';

const FooterTop = () => {
  return (
    <div className="footer-top">
      {/* Add your content for the top section of the footer here */}
      <p></p>
    </div>
  );
};

export default FooterTop;